import { motion } from "framer-motion";


const HeadingAnimation =({text, className, transitionDuration, setHeadingCompleted }) => {
    const textChars = text.split("");

    const charVariants = {
      hidden: { opacity: 0 },
      reveal: { opacity: 1 },
    };
  
    return(
    <motion.h1
    className={className}
    initial="hidden"
    whileInView="reveal"
    transition={{ staggerChildren: 0.03 }}
    viewport={{ once: true }}
    onAnimationComplete={() => {setHeadingCompleted(true);}}
  >
    {textChars.map((char, index) => (
      <motion.span
        key={`${char}-${index}`}
        transition={{ duration: transitionDuration }}
        variants={charVariants}
      >
        {char}
      </motion.span>
    ))}
  </motion.h1>
);

}

export default HeadingAnimation;