import React, {useState} from "react";
import style from "./banner1.module.css";
import graph from "../../assets/graph.png";
import HeadingAnimation from "../../components/animations/heading";
import SubHeadingAnimation from "../../components/animations/subHeading";
import ButtonAnimation from "../animations/button";

const Banner1 = () => {
  const heading = "Borderless Digital Payments"
  const sub = "Zero knowledge payments that provide cross-borders solutions for individuals to seamlessly send cryptocurrency to family and friends."
  const [headingCompleted, setHeadingCompleted] = useState(false);

  return (
      <div className={style.card} id="individual" >
        <div className={style.left}>
        <HeadingAnimation 
            text={heading}
            transitionDuration={0.5}
            className={style.heading}
            setHeadingCompleted={setHeadingCompleted}
            />
            {headingCompleted && (
            <SubHeadingAnimation
            text={sub}
            className={style.sub}
            transitionDuration={0.35}
            />
            )}
        </div>
        <div className={style.right}>
        <img src={graph} alt="img" className={style.img} />
        <ButtonAnimation 
        className={style.button}
        text="Individual"
        />
</div>
       </div>
  );
};

export default Banner1;
