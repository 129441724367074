import { motion } from "framer-motion";


const ButtonAnimation =({text, className }) => {

    return(
        <motion.button
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}

        transition={{
            duration: 0.9, // Overall duration
            scale: { type: "spring", duration: 1.5, bounce: 0.4 }, // Longer duration for scale
        }}
        className={className}
    >
      {text}
      </motion.button>
);

}

export default ButtonAnimation;