import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import style from "./header.module.css";

const Header = () => {
  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(``); // State to track active link



  const menuOpener = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeHandler = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  // const handleLinkClick = (link) => {
  //   setActiveLink(link);
  //   closeHandler();
  // };
  const handleLinkClick = (link) => {
    setActiveLink(link);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    closeHandler();
  };

  console.log("ActiveLink", activeLink);

  return (
    <>
      <div className={style.header}>
        <div className={style.logoC} onClick={() => history("/")}>
          <span className={style.title}>directoapp</span>
        </div>
        {!isOpen && <FiMenu onClick={menuOpener} className={style.menuIcon} />}
        {isOpen && (
          <IoClose onClick={closeHandler} className={style.menuIcon} />
        )}
        <div className={style.items}>
          <Link
            // to="/"
            className={activeLink === "individual" ? style.active : ""}
            onClick={() => handleLinkClick("individual")}

          >
            Individual
          </Link>
          <Link
            // to="https://nuconsortium.xyz/"
            className={activeLink === "business" ? style.active : ""}
            onClick={() => handleLinkClick("business")}

          >
            Business
          </Link>
          <Link
            // to="/dxs"
            className={activeLink === "plugins" ? style.active : ""}
            onClick={() => handleLinkClick("plugins")}
          >
            Plugins
          </Link>
          <Link
            to="https://nublock.xyz/"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            nuBlock
          </Link>
          <Link
            to="https://directoapp.gitbook.io/directoapp.com"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            Documents
          </Link>
          <Link
            to="https://t.me/directoapp"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            Connect
          </Link>
        </div>
      </div>
      <div className={isOpen ? style.open : style.mobileM}>
        <div className={style.links}>
          <Link
            // to="/"
            className={activeLink === "individual" ? style.active : ""}
            onClick={() => handleLinkClick("individual")}
          >
            Individual
          </Link>
          <Link
            // to="https://nuconsortium.xyz/"
            className={activeLink === "business" ? style.active : ""}
            onClick={() => handleLinkClick("business")}
          >
            Business
          </Link>
          <Link
            // to="/dxs"
            className={activeLink === "plugins" ? style.active : ""}
            onClick={() => handleLinkClick("plugins")}
          >
            Plugins
          </Link>
          <Link
            to="https://nublock.xyz/"
            className={activeLink === "nu" ? style.active : ""}
            onClick={() => handleLinkClick("nu")}
          >
            nuBlock
          </Link>
          <Link
            to="https://directoapp.gitbook.io/directoapp.com"
            className={activeLink === "documents" ? style.active : ""}
            onClick={() => handleLinkClick("contact")}
          >
            Documents
          </Link>
          <Link
            to="https://t.me/directoapp"
            className={activeLink === "contact" ? style.active : ""}
            onClick={() => handleLinkClick("contact")}
          >
            Connect
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
