import React, { useState } from "react";
import style from "./banner2.module.css";
import graph from "../../assets/graph2.png"
import HeadingAnimation from "../animations/heading";
import SubHeadingAnimation from "../animations/subHeading";
import ButtonAnimation from "../animations/button";

const Banner2 = () => {
  const heading = "Got a Business? Join directoapp’s Blockchain Invoice Management & Payment Service"
  const sub = "directoapp is for companies of all sizes to process digital transactions in cryptocurrencies, and stablecoins, to accept payments, send payouts, and Invoicing, to help companies reach their maximum potential."

  const [headingCompleted, setHeadingCompleted] = useState(false);
  return (
    <div className={style.card} id="business" >
    <div className={style.left}>
      {/* <h1 className={style.heading}>
      Got a Business? Join directoapp’s Blockchain Invoice Management & Payment Service
      </h1>
      <p className={style.sub}>
      directoapp is for companies of all sizes to process digital transactions in cryptocurrencies, and stablecoins, to accept payments, send payouts, and Invoicing, to help companies reach their maximum potential.
      </p> */}
       <HeadingAnimation 
            text={heading}
            transitionDuration={0.5}
            className={style.heading} 
            setHeadingCompleted={setHeadingCompleted}
            />
            {headingCompleted ? (
            <SubHeadingAnimation
            text={sub}
            className={style.sub}
            transitionDuration={0.35}
            />
            ): (
              <div
                style={{
                  visibility: "hidden",
                  fontSize: "1.6rem",
                  marginTop: "1rem",
                }}
              >
                {sub}
              </div>
            )}

    </div>
    <div className={style.right}>
    <img src={graph} alt="img" className={style.img} />
    <ButtonAnimation 
        className={style.button}
        text="Business"
        />
    {/* <button className={style.button}>
      Business
    </button> */}


</div>
   </div>
  );
};

export default Banner2;