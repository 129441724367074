/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import style from "./banner3.module.css";
import HeadingAnimation from "../animations/heading";
import SubHeadingAnimation from "../animations/subHeading";
import ButtonAnimation from "../animations/button";

const Banner3 = () => {
  const heading = `No Code API's to Scale your Projects Revenue`;
  const sub = `Authenticate your payments to bridge with your project's customer.`;

  const [headingCompleted, setHeadingCompleted] = useState(false);

  return (
    <div className={style.card} id="plugins">
      <div className={style.left}>
        <HeadingAnimation
          text={heading}
          transitionDuration={0.5}
          className={style.heading}
          setHeadingCompleted={setHeadingCompleted}
        />
        {headingCompleted ? (
          <SubHeadingAnimation
            text={sub}
            className={style.sub}
            transitionDuration={0.35}
          />
        ): (
          <div
          style={{
            visibility: "hidden",
            fontSize: "1.6rem",
            marginTop: "1rem",
          }}
        >
          {sub}
        </div>
        )}
      </div>
      <div className={style.right}>
        <div className={style.apidiv}>
          <p className={style.apitext}>
            // API endpoints
            <br />
            app.post(&apos;/register&apos;, async (req, res) =&gt; &#123;
            <br />
            &nbsp;&nbsp;const &#123; username, email, password &#125; =
            req.body;
            <br />
            &#125;);
          </p>
        </div>
        <ButtonAnimation className={style.button} text="Plugins" />
      </div>
    </div>
  );
};

export default Banner3;
