import React, {useState} from "react";
import style from "./footer.module.css";
import { Link, useNavigate } from "react-router-dom";
import insta from "../../../assets/instagram.png";
import twitter from"../../../assets/twitter.png";
import linkedin from "../../../assets/linkedin.png";
import telegram from "../../../assets/telegram.png";
import nlogo from "../../../assets/nlogo.png";



const Footer = () => {
  const history = useNavigate();
  const currentPath = window.location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(`${currentPath}`); // State to track active link


  const closeHandler = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
    const element = document.getElementById(link);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    closeHandler();
  };

  return (
    <div className={style.main}>
        <div className={style.header}>
        <div className={style.logoC} onClick={() => window.scrollTo(0, 0)}>
          <span className={style.title}>directoapp</span>
        </div>
        <div className={style.items}>
        <Link
            // to="/"
            className={activeLink === "individual" ? style.active : ""}
            onClick={() => handleLinkClick("individual")}

          >
            Individual
          </Link>
          <Link
            // to="https://nuconsortium.xyz/"
            className={activeLink === "business" ? style.active : ""}
            onClick={() => handleLinkClick("business")}

          >
            Business
          </Link>
          <Link
            // to="/dxs"
            className={activeLink === "plugins" ? style.active : ""}
            onClick={() => handleLinkClick("plugins")}
          >
            Plugins
          </Link>
          <Link
            to="https://nublock.xyz/"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            nuBlock
          </Link>
          <Link
            to="https://directoapp.gitbook.io/directoapp.com"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            Documents
          </Link>
          <Link
            to="https://t.me/directoapp"
            className={activeLink === "contact" ? style.active : ""}
            // onClick={() => handleLinkClick("contact")}
          >
            Connect
          </Link>
        </div>
      </div>
{/* 
      <div className={style.socials}>
        <a
          href=" https://nublock.page.link/KATpZSWaZyQum3Gv8"
          target="_blank"
          rel="noopener noreferrer"
          className={style.nlogo}
        >
          <img alt="img" src={nlogo} className={style.nlogo} />
        </a>
        <a
          href="https://x.com/0xdirectoapp  "
          target="_blank"
          rel="noopener noreferrer"
          className={style.twitter}
        >
          <img alt="img" src={twitter} className={style.twitter} />
        </a>
        <a
          href="https://www.instagram.com/0xdirectoapp/ "
          target="_blank"
          rel="noopener noreferrer"
          className={style.link}
        >
          <img alt="img" src={insta} className={style.link} />
        </a>
        <a
          href="https://www.linkedin.com/company/0xdirectoapp"
          target="_blank"
          rel="noopener noreferrer"
          className={style.link}
        >
          <img alt="img" src={linkedin} className={style.link} />
        </a>
      </div> */}
   
      <div className={style.linktree}>
        <p className={style.directoapp}> 2025 directoapp Inc. All Rights Reserved.  </p>
        
        {/* <p className={style.linkedin}> nuBlock   X   Linkedin  </p> */}
        <div className={style.socials}>
        <a
          href=" https://nublock.page.link/KATpZSWaZyQum3Gv8"
          target="_blank"
          rel="noopener noreferrer"
          className={style.nlogo}
        >
          <img alt="img" src={nlogo} className={style.nlogo} />
        </a>
        <a
          href="https://x.com/0xdirectoapp  "
          target="_blank"
          rel="noopener noreferrer"
          className={style.twitter}
        >
          <img alt="img" src={twitter} className={style.twitter} />
        </a>
        {/* <a
          href="https://t.me/joinnu"
          target="_blank"
          rel="noopener noreferrer"
          className={style.link}
        >
          <img alt="img" src={telegram} className={style.link} />
        </a> */}
        <a
          href="https://www.instagram.com/0xdirectoapp/ "
          target="_blank"
          rel="noopener noreferrer"
          className={style.link}
        >
          <img alt="img" src={insta} className={style.link} />
        </a>
        <a
          href="https://www.linkedin.com/company/0xdirectoapp"
          target="_blank"
          rel="noopener noreferrer"
          className={style.link}
        >
          <img alt="img" src={linkedin} className={style.link} />
        </a>
      </div>
      </div>
    </div>
  );
};

export default Footer;
