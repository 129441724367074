import Banner1 from "../components/landing/Banner1";
import Banner2 from "../components/landing/Banner2";
import style from "../pages/landing.module.css";
import Banner3 from "../components/landing/Banner3";
import { useState } from "react";
import HeadingAnimation from "../components/animations/heading";
import SubHeadingAnimation from "../components/animations/subHeading";
import {Helmet} from 'react-helmet';

const LandingPage = () => {
  const heading =
    "Payments for the Digital Age to Connect, and Transact with Fewer Fees";
  const sub =
    "Build borderless crypto solutions. Zero-knowledge payments for developers and businesses to scale without barriers.";

  const [headingCompleted, setHeadingCompleted] = useState(false);

  return (
    <>
     <Helmet>
        <title>directoapp</title>
        <meta
          name="description"
          content="directoapp is a borderless digital payments network for developers and businesses."
        />
        <meta
          name="keywords"
          content="social, directo, directoapp, payments, crypto, nu, nublock, nuconsortium"
        />
        <meta name="author" content="directoapp" />
        <meta property="og:title" content="directoapp" />
        <meta
          property="og:description"
          content="directoapp is a borderless digital payments network for developers and businesses."
        />
        <meta property="og:url" content="https://directoapp.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"%PUBLIC_URL%/logo.png"}
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:width" content="200" />
        <meta name="twitter:title" content="directoapp" />
        <meta
          name="twitter:description"
          content="nuBlock is a decentralized social blockchain consortium where you socialize, send payments, and trade crypto."
        />
        <meta
          name="twitter:image"
          content={"%PUBLIC_URL%/logo.png"}
        />
        <meta name="twitter:site" content="@joinnu_" />
      </Helmet>
      <div className={style.card}>
        <div className={style.overlay}>
          <div className={style.content}>
            <HeadingAnimation
              text={heading}
              transitionDuration={0.5}
              className={style.heading}
              setHeadingCompleted={setHeadingCompleted}
            />
            {headingCompleted ? (
              <SubHeadingAnimation
                text={sub}
                className={style.sub}
                transitionDuration={0.35}
              />
            ) : (
              <div
                style={{
                  visibility: "hidden",
                  fontSize: "1.6rem",
                  marginTop: "1rem",
                }}
              >
                {sub}
              </div>
            )}
          </div>
        </div>
      </div>
      <Banner1 />
      <Banner2 />
      <Banner3 />
    </>
  );
};

export default LandingPage;
